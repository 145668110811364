import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

function CardApresentacao() {
  return (
    <Card style={{ width: "80%", marginLeft: "10%", marginTop: "5%" }}>
      <Card.Img variant="top" src="https://i.imgur.com/suNBRq9.jpeg" />
      <Card.Body data-bs-theme="dark" className="transicao">
        <Card.Title>Um pouco sobre o RMV</Card.Title>
        <Card.Text>
          No momento faço minha segunda graduação em Engenharia de Computação
          pelo Instituto Infnet. Sou contador pelo IBMEC-RJ (sim, passei no
          exame do conselho) e, apesar de não ter exercido a profissão, ela me
          agregou muito na questão de visão de mundo. Não, as Ciências Contábeis
          não são exatas, são sociais assim como economia e administração, coisa
          que vi bastante durante a graduação. Também fui monitor de Cálculo
          Diferencial e Integral 1 por quatro anos, sendo três na UFF e um no
          IBMEC. Gosto muito de jogos online, em particular MMOs, mas estou em
          abstinência, tentando aproveitar melhor o meu tempo, como construir
          este site ou estudar assuntos diversos.
          <br />
          Despertam a minha atenção o desenvolvimento Back-End e Bancos de Dados
          (até agora). Fazer relatórios do Google Looker Studio também é muito
          legal. Não vi o suficiente de hardware para saber se vou me apaixonar
          ou não... Talvez sim, estou com uma grande expectativa de aprofundar
          no assunto.
          <br />
          Acho que já escrevi muito. Não deixo o link do GitHub pois 99% do que
          está lá é privado, e mesmo assim está uma zona completa, já que eu
          coloco todos os trabalhos da faculdade em um repositório privado, para
          que outros não se "inspirem" no meu código, se é que você me entende.
        </Card.Text>
        <Button
          variant="info"
          onClick={() => alert("Olá Vitor. Em construção.")}
        >
          Não tem nada aqui
        </Button>
      </Card.Body>
    </Card>
  );
}

export default CardApresentacao;
