import "../App.css";
function trocaTema(){
    let index=document.getElementsByClassName("transicao");
    var i=0;
    for (i=0;i<index.length;i++){
        if (index[i].getAttribute("data-bs-theme").valueOf()==="light"){
            index[i].setAttribute("data-bs-theme","dark"); 
            index[i].classList.remove("theme-transition-dark");
            index[i].classList.add("theme-transition-light");
        }else{
            index[i].setAttribute("data-bs-theme","light"); 
            index[i].classList.remove("theme-transition-light");
            index[i].classList.add("theme-transition-dark");
        };  
    };    
}

export default trocaTema;