import CardApresentacao from "./CardApresentacao";
import { Container } from "react-bootstrap";
function Sobre() {
  return (
    <Container className="mb-5">
      <h1>SOBRE</h1>
      <hr />
      <h3>Meu nome é Rodrigo "RMV" Vianna, e é um prazer tê-lo por aqui!</h3>
      <p>
        Site iniciado em 29/04/2024. Anotei aqui para não esquecer, e depois
        movo para um footer ou algo do tipo.
        <br />
        <CardApresentacao />
      </p>
    </Container>
  );
}
export default Sobre;
