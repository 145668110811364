import { Container } from "react-bootstrap";
function Home(){
    return(
    <Container className="mb-5">    
    <h1>
        HOME
    </h1>
    <hr/>
        <h3>Bem vindo ao meu laboratório!</h3>
        <h6>DISCLAIMER: Eu não gosto de front-end. Mas depois que um professor me mostrou como hospedar um site, não resisti à tentação.</h6>
        <p>
            Feita esta primeira observação, este é o lugar que eu escolhi para fazer experiências, ter sucesso e fracasso.<br/>
            O primeiro fracasso foi tentar integrar React e Bootstrap.
            O primeiro sucesso foi achar o framework React-Bootstrap, que faz exatamente o que eu estava buscando.
        </p>
    </Container>
    );
};
export default Home;